@font-face {
  font-family: 'Gabriel Sans';
  font-weight: 800;
  src: local('Gabriel Sans'),
    url(./fonts/Gabriel-Sans-Black.otf) format('opentype');
}
@font-face {
  font-family: 'Gabriel Sans';
  font-weight: 700;
  src: local('Gabriel Sans'),
    url(./fonts/Gabriel-Sans-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Gabriel Sans';
  font-weight: 500;
  src: local('Gabriel Sans'),
    url(./fonts/Gabriel-Sans-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Gabriel Sans';
  font-weight: 400;
  src: local('Gabriel Sans'),
    url(./fonts/Gabriel-Sans-Normal.otf) format('opentype');
}

@font-face {
  font-family: 'Gabriel Sans Cond';
  font-weight: 800;
  src: local('Gabriel Sans'),
    url(./fonts/Gabriel-Sans-Cond-Black.otf) format('opentype');
}
@font-face {
  font-family: 'Gabriel Sans Cond';
  font-weight: 700;
  src: local('Gabriel Sans'),
    url(./fonts/Gabriel-Sans-Cond-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Gabriel Sans Cond';
  font-weight: 500;
  src: local('Gabriel Sans'),
    url(./fonts/Gabriel-Sans-Cond-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Gabriel Sans Cond';
  font-weight: 400;
  src: local('Gabriel Sans'),
    url(./fonts/Gabriel-Sans-Cond-Normal.otf) format('opentype');
}
